<template>
    <div>
        <Breadcrumb></Breadcrumb>
        <div class="header">
            <el-row>
                <div class="form">
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-form-item label="名称">
                            <el-input v-model="searchForm.name" placeholder="名称" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="分类">
                            <el-select v-model="searchForm.type" clearable placeholder="分类" size="small">
                                <el-option v-for="cookBookType in checkCookBookType" :key="cookBookType.id" :label="cookBookType.name" :value="cookBookType.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="标签">
                            <el-select v-model="searchForm.tags" filterable multiple clearable placeholder="标签" size="small">
                                <el-option v-for="cookBookTags in checkCookBookTags" :key="cookBookTags.id" :label="cookBookTags.name" :value="cookBookTags.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" icon="el-icon-search" @click="getCookBookList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-button type="danger" size="small" @click="delCookBook()">批量删除</el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" size="small" @click="showForm('add')">
                        <i class="el-icon-circle-plus-outline"></i>
                        添加
                    </el-button>
                </el-col>
            </el-row>
        </div>
        <div class="content">
            <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" :default-sort="{ prop: 'id', order: 'ascending' }">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="type" label="分类" :formatter="typeFormatter"></el-table-column>
                <el-table-column prop="intro" label="简介"></el-table-column>
                <el-table-column prop="ingredients" label="食材"></el-table-column>
                <el-table-column prop="tags" label="标签" :formatter="tagsFormatter"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" sortable></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="showForm('edit', scope.row.id)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="delCookBook(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <Pagination :total="total" :perPage="perPage" :currentPage="currentPage" @currentPageChange="currentPageChange"></Pagination>
            </div>
        </div>

        <AddForm v-if="addFormDialog" :addFormDialog="addFormDialog" @closeForm="closeForm" @getCookBookList="getCookBookList"></AddForm>
        <EditForm v-if="editFormDialog" :editFormDialog="editFormDialog" :editFormId="editFormId" @closeForm="closeForm" @getCookBookList="getCookBookList"></EditForm>
    </div>
</template>

<script>
import { getCookBookList, delCookBook } from '@/utils/servers/cookBook.js';
import { getCheckCookBookType } from '@/utils/servers/cookBookType.js';
import { getCheckCookBookTags } from '@/utils/servers/cookBookTags.js';
import Pagination from '@/components/Pagination/Pagination.vue';
import AddForm from './add.vue';
import EditForm from './edit.vue';
export default {
    components: {
        Pagination,
        AddForm,
        EditForm
    },
    computed: {
        typeFormatter() {
            return (row, column, value) => {
                let arr = this.checkCookBookType.find(val => val.id === value);
                if (arr) {
                    return arr.name;
                } else {
                    return value;
                }
            };
        },
        tagsFormatter() {
            return (row, column, value) => {
                let ids = value.split(','); // 将逗号分隔的值转换为数组
                let names = ids.map(id => {
                    let arr = this.checkCookBookTags.find(val => val.id == id);
                    return arr ? arr.name : id; // 如果找到匹配的标签对象，则返回其名称；否则返回原始的值
                });
                return names.join(', '); // 将替换后的值用逗号连接并返回
            };
        }
    },
    data() {
        return {
            searchForm: {
                name: '',
                type: '',
                tags: ''
            },
            list: [],
            total: 0,
            perPage: 0,
            currentPage: 0,
            checkIds: [],
            checkCookBookType: [],
            checkCookBookTags: [],
            addFormDialog: false,
            editFormDialog: false,
            editFormId: 0
        };
    },
    created() {
        this.getCheckCookBookType();
        this.getCheckCookBookTags();
        this.getCookBookList();
    },
    methods: {
        handleSelectionChange(val) {
            this.checkIds = val ? val.map(item => item.id) : [];
        },
        currentPageChange(val) {
            this.currentPage = val;
            this.getCookBookList();
        },
        getCookBookList() {
            getCookBookList({ searchForm: this.searchForm, perPage: this.perPage, currentPage: this.currentPage }).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.list = data.data.data;
                    this.total = data.data.total;
                    this.perPage = data.data.per_page;
                    this.currentPage = data.data.current_page;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckCookBookType() {
            getCheckCookBookType().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkCookBookType = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckCookBookTags() {
            getCheckCookBookTags().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkCookBookTags = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        delCookBook(id) {
            var ids = id ? id : this.checkIds.toString();
            this.$confirm('此操作将永久删除数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delCookBook({ ids: ids }).then(res => {
                    var data = res;
                    if (data.code == 1) {
                        this.$message.success(data.msg);

                        if (this.total % this.perPage === 1) {
                            this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
                        }

                        this.getCookBookList();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        showForm(name, id = 0) {
            if (name == 'add') {
                this.addFormDialog = true;
            } else if (name == 'edit') {
                this.editFormDialog = true;
                this.editFormId = id;
            }
        },
        closeForm(name) {
            if (name == 'add') {
                this.addFormDialog = false;
            } else if (name == 'edit') {
                this.editFormDialog = false;
            }
        }
    }
};
</script>

<style lang="less" scoped>
.header {
    background: #fff;
    margin-bottom: 20px;
}
.content {
    .pagination {
        padding: 10px;
    }
}
</style>
