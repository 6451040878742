<template>
    <div>
        <el-dialog :visible.sync="formDialog" title="编辑" :close-on-click-modal="false" @close="closeForm">
            <el-form ref="dataForm" :model="dataForm" :rules="ruleForm" label-width="100px" class="data-form">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="dataForm.name" placeholder="用户名"></el-input>
                </el-form-item>
                <el-form-item label="分类" prop="type">
                    <el-select v-model="dataForm.type" placeholder="选择分类">
                        <el-option v-for="item in checkCookBookType" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="简介" prop="intro" style="width: 800px">
                    <el-input type="textarea" placeholder="简介" v-model="dataForm.intro" maxlength="255" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="食材" prop="ingredients" style="width: 800px">
                    <el-input type="textarea" placeholder="食材" v-model="dataForm.ingredients" maxlength="255" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="做法" prop="content" style="width: 800px">
                    <el-input type="textarea" placeholder="做法" v-model="dataForm.content" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="照片" prop="img" style="width: 800px">
                    <el-upload 
                        action="/" 
                        list-type="picture-card" 
                        :on-preview="handleImgPreview" 
                        :on-remove="handleImgRemove" 
                        :before-remove="beforeRemove" 
                        :http-request="uploadImg" 
                        :file-list="imgList"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="视频" prop="video" style="width: 800px">
                    <el-upload
                        action="/"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :http-request="uploadFile"
                        multiple
                        :limit="3"
                        :on-exceed="handleExceed"
                        :file-list="fileList"
                    >
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传MP4,AVI,MOV文件</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="标签" prop="tags">
                    <el-checkbox-group v-model="dataForm.tags" :min="0" :max="4">
                        <el-checkbox v-for="tag in checkCookBookTags" :label="tag.id" :key="tag.id" :checked="dataForm.tags.indexOf(tag.id.toString()) !== -1">{{ tag.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="卡通背景图" prop="background">
                    <el-radio-group v-model="dataForm.background">
                        <el-radio v-for="background in checkBackground" :label="background.url" :key="background.id">{{ background.url }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" prop="note">
                    <el-input v-model="dataForm.note" placeholder="备注"></el-input>
                </el-form-item>
                <el-form-item label="吃货币" prop="amount">
                    <el-input type="number" :min="0" v-model="dataForm.amount" placeholder="吃货币"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm()">提交</el-button>
                    <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import { getCheckCookBookType } from '@/utils/servers/cookBookType.js';
import { getCheckCookBookTags } from '@/utils/servers/cookBookTags.js';
import { getCookBookInfo, editCookBook } from '@/utils/servers/cookBook.js';
import { upload } from '@/utils/servers/upload';
export default {
    props: ['editFormDialog', 'editFormId'],
    data() {
        return {
            imgUids: [],
            fileUids: [],
            imgList: [],
            fileList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            formDialog: this.editFormDialog,
            dataForm: {
                id: '',
                name: '',
                type: '',
                intro: '',
                ingredients: '',
                content: '',
                img: [],
                video: [],
                tags: [],
                background: '',
                note: '',
                amount: 0.00
            },
            ruleForm: {
                name: [{ required: true, trigger: 'blur' }],
                type: [{ required: true, trigger: 'blur' }],
                intro: [{ required: true, trigger: 'blur' }],
                ingredients: [{ required: true, trigger: 'blur' }],
                content: [{ required: true, trigger: 'blur' }],
                img: [{ required: true, trigger: 'blur' }],
                // video: [{ required: true, trigger: 'blur' }],
                // tags: [{ required: true, trigger: 'blur' }],
                // background: [{ required: true, trigger: 'blur' }],
                // note: [{ required: true, trigger: 'blur' }],
                amount: [{ required: true, trigger: 'blur' }]
            },
            checkCookBookType: [],
            checkCookBookTags: [],
            checkBackground: [],
        };
    },
    created() {
        this.getCheckCookBookType();
        this.getCheckCookBookTags();
        this.getCookBookInfo();
    },
    methods: {
        handleImgRemove(file) {
            let index = this.imgUids.findIndex(item => item === file.uid); // 根据唯一标识找到被删除文件的索引
            if (index !== -1) {
                this.imgUids.splice(index, 1); // 删除 imgUids 数组中的相应元素
                this.dataForm.img.splice(index, 1); // 删除 img 数组中的相应元素
            }
        },
        handleImgPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        uploadImg(item) {
            const form = new FormData();
            form.append('file', item.file);
            form.append('path', 'cook-book');
            upload(form).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.imgUids.push(item.file.uid);
                    this.dataForm.img.push(data.data.url);
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        handleRemove(file) {
            let index = this.fileUids.findIndex(item => item === file.uid); // 根据唯一标识找到被删除文件的索引
            if (index !== -1) {
                this.fileUids.splice(index, 1); // 删除 fileUids 数组中的相应元素
                this.dataForm.video.splice(index, 1); // 删除 video 数组中的相应元素
            }
        },
        handlePreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        uploadFile(item) {
            const form = new FormData();
            form.append('file', item.file);
            form.append('path', 'cook-book');
            upload(form).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.fileUids.push(item.file.uid);
                    this.dataForm.video.push(data.data.url);
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckCookBookType() {
            getCheckCookBookType().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkCookBookType = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckCookBookTags() {
            getCheckCookBookTags().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkCookBookTags = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCookBookInfo() {
            getCookBookInfo(this.editFormId).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.dataForm.id = data.data.id;
                    this.dataForm.name = data.data.name;
                    this.dataForm.type = data.data.type;
                    this.dataForm.intro = data.data.intro;
                    this.dataForm.ingredients = data.data.ingredients;
                    this.dataForm.content = data.data.content;
                    this.dataForm.img = data.data.img ? data.data.img.split(',') : [];
                    this.dataForm.img.forEach((value, index) => {
                        let img = {
                            uid: index + Date.now(),
                            name: value,
                            url: value,
                        };
                        this.imgUids.push(img.uid);
                        this.imgList.push(img);
                    });
                    this.dataForm.video = data.data.video ? data.data.video.split(',') : [];
                    this.dataForm.video.forEach((value, index) => {
                        let file = {
                            uid: index + Date.now(),
                            name: value,
                            url: value,
                        };
                        this.fileUids.push(file.uid);
                        this.fileList.push(file);
                    });
                    this.dataForm.tags = data.data.tags ? data.data.tags.split(',') : [];
                    console.log(this.checkCookBookTags)
                    console.log(this.dataForm.tags)
                    this.dataForm.background = data.data.background;
                    this.dataForm.note = data.data.note;
                    this.dataForm.amount = data.data.amount;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        submitForm() {
            editCookBook(this.dataForm.id, this.dataForm).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.$message.success(data.msg);
                    this.closeForm();
                    this.$emit('getCookBookList');
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        resetForm() {
            this.$refs.dataForm.resetFields();
        },
        closeForm() {
            this.$emit('closeForm', 'edit');
        }
    }
};
</script>

<style lang="less" scoped>
.data-form {
    width: 400px;
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>
